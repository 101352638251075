import { isUserLogged } from "./auth";
import { openModal, signUpModal, videoProModal } from "./popup";
import {getRedirectUrl} from "./helpers";
import {CLIENT_MAIN_PAGE, CLIENT_PRO_PLAYERS} from "./constants";
import {sendAmplitudeButtonEvent} from "./amplitude";

const findMoreProsButtons = document.querySelectorAll('.showAllButton');

[...findMoreProsButtons].forEach((el => {
    el.addEventListener('click', () => {
        sendAmplitudeButtonEvent('Show all button');

        if (isUserLogged()) {
            window.location.href = getRedirectUrl(CLIENT_MAIN_PAGE);
        } else {
            openModal(signUpModal);
        }
    })
}));

const morePlayersCards = document.querySelectorAll('.more-players-card');

[...morePlayersCards].forEach((el => {
    el.addEventListener('click', () => {
        if (isUserLogged()) {
            window.location.href = getRedirectUrl(CLIENT_PRO_PLAYERS);
        } else {
            openModal(signUpModal);
        }
    })
}))

const popupSignUpBtn = document.querySelector(".sign-up-popup-trigger");

popupSignUpBtn.addEventListener("click", () => {
    sendAmplitudeButtonEvent('Sign up button click');
    if (isUserLogged()) {
        window.location.href = getRedirectUrl(CLIENT_PRO_PLAYERS);
    } else {
        openModal(signUpModal);
        videoProModal.classList.remove("is--visible");
    }
});


const loginButton = document.querySelector('#login-button');

loginButton.addEventListener("click", () => {
    sendAmplitudeButtonEvent('Play button click');
    window.location.href = getRedirectUrl(CLIENT_MAIN_PAGE);
});
